<template>
  <div>
    <va-alert v-if="loginError" color="danger" icon="info" class="mb-4">
      Les identifiants sont incorrects.
    </va-alert>
    <form @submit.prevent="onsubmit">
      <va-input
        class="mb-3"
        v-model="email"
        type="email"
        :label="$t('auth.email')"
        :error="!!emailErrors.length"
        :error-messages="emailErrors"
      />

      <va-input
        class="mb-3"
        v-model="password"
        type="password"
        :label="$t('auth.password')"
        :error="!!passwordErrors.length"
        :error-messages="passwordErrors"
      />

      <!--      <div class="auth-layout__options d-flex align&#45;&#45;center justify&#45;&#45;space-between">-->
      <!--        <va-checkbox v-model="keepLoggedIn" class="mb-0" :label="$t('auth.keep_logged_in')"/>-->
      <!--        <router-link class="ml-1 link" :to="{name: 'recover-password'}">{{$t('auth.recover_password')}}</router-link>-->
      <!--      </div>-->

      <div class="d-flex justify--center mt-3">
        <va-button type="submit" :loading="loading" class="my-0">{{ $t('auth.login') }}</va-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      keepLoggedIn: false,
      emailErrors: [],
      passwordErrors: [],
      loading: false,
      loginError: false,
    }
  },
  computed: {
    formReady () {
      return !this.emailErrors.length && !this.passwordErrors.length
    },
  },
  methods: {
    async onsubmit () {
      this.emailErrors = this.email ? [] : ['Email is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      if (!this.formReady) {
        return
      }
      try {
        this.loading = true
        await this.axios.get('/sanctum/csrf-cookie')
        await this.axios.post('/api/guide/login', {
          'email': this.email,
          'password': this.password
        })
        await this.$router.push({ name: 'dashboard' })
      } catch (e) {
        this.loginError = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
